.button-group {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--s4);

  container-type: inline-size;

  &--scroll {
    overflow: scroll;
  }
}

.button {
  flex: 1;
  user-select: none;

  .button-group--scroll & {
    flex: unset;
    width: 28cqw;
    flex-shrink: 0;
  }

  font: inherit;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: var(--s3);

  border: none;
  border-radius: 2px;
  outline: none;

  background-color: transparent;
  border: 1px solid var(--black);
  color: var(--black);
  padding: var(--s3);
}

.button-label {
  flex: 1;
  display: flex;
  align-items: center;
}
