.button {
  margin: 0;
  padding: var(--s3);
  font-size: var(--b1);
  line-height: 1em;
  background-color: var(--black);
  color: var(--white);
  border: none;
  border-radius: 2px;
  outline: none;
  font-weight: bold;
  width: 100%;

  &:disabled {
    color: var(--gray);
    opacity: 0.5;
  }
}
