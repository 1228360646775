@use 'src/core';

.card {
  @include core.card;
}

.card-header {
  @include core.card-header;
}

.card-content {
  @include core.card-content;
}

.card-content-inner {
  @include core.card-content-inner;
}

.card-toggle-group {
  @include core.card-toggle-group;
}

.card-toggle {
  @include core.card-toggle;
}
