body {
  margin: 0;
  font-family: system-ui;
  font-size: 16px;

  background: var(--white);
  color: var(--black);

  // https://developer.mozilla.org/en-US/docs/Web/CSS/touch-action#manipulation
  touch-action: manipulation;
}

:root {
  --black: black;
  --white: white;
  --gray: hsl(0, 0%, 50%);

  --card: hsl(0, 0%, 10%);
  --card-outer-border: hsl(0, 0%, 30%);
  --card-inner-border: hsl(0, 0%, 20%);

  --white-100: hsl(0, 0%, 80%);

  --green-100: hsl(120, 75%, 75%);
  --yellow-100: hsl(60, 75%, 75%);
  --red-100: hsl(0, 75%, 75%);

  // general sizes
  --s1: 0.5vmin;
  --s2: 1vmin;
  --s3: 2vmin;
  --s4: 4vmin;

  // body font sizes
  --b1: 0.8em;
  --b2: 1em;

  // header font sizes
  --h1: 2em;
  --h2: 1.66em;
  --h3: 0.8em;
}

@media (prefers-color-scheme: dark) {
  :root {
    --black: white;
    --white: black;

    --white-100: hsl(0, 0%, 20%);
  }
}

@media not (prefers-reduced-motion) {
  body {
    transition:
      background-color 0.25s ease,
      color 0.25s ease;
  }
}
