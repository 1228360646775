@use 'src/core';

.block-view {
  flex: 1;
  padding: var(--s4) 0;

  display: flex;
  flex-direction: column;
  gap: var(--s4);

  will-change: transform;

  padding-bottom: 100lvh;
}

.heading {
  padding: 0 var(--s4);
}
