.container {
  display: flex;
  flex-direction: column;
  gap: var(--s3);
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.queue {
  padding: var(--s3);
  display: flex;
  gap: var(--s3);
  flex-direction: row-reverse;
  background-color: var(--card-outer-border);

  overflow-x: scroll;

  container-type: inline-size;
}

.queue-item {
  background-color: var(--card);
  width: calc(100cqw / 3.4);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  will-change: transform;

  &--placeholder {
    visibility: hidden;
  }
}

.queue-item-inner {
  padding: var(--s3);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--s3);
}

.queue-item-progress {
  height: var(--s3);
  background-color: var(--green-100);
}
