@mixin button-link {
  text-decoration: none;
  background-color: var(--black);
  color: var(--white);
  padding: var(--s4);

  font-weight: bold;
  text-align: center;
  border-radius: 2px;
}

@mixin card {
  background-color: var(--card);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  will-change: transform;
}

@mixin card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--s4);
}

@mixin card-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  will-change: transform;
}

@mixin card-content-inner {
  padding: var(--s4);
  padding-top: 0;
  flex-shrink: 0;
  display: flex;
  gap: var(--s4);
  flex-direction: column;
  will-change: transform;
}

@mixin card-toggle-group {
  display: flex;
}

@mixin card-toggle {
  font: inherit;
  color: inherit;
  background: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: var(--s4);
}
