.text {
  color: var(--black);
}

.b1 {
  font-size: var(--b1);
  line-height: calc(var(--b1) * 1.2);
}

.b2 {
  font-size: var(--b2);
  line-height: calc(var(--b2) * 1.2);
}

.invert {
  color: var(--white);
}

.bold {
  font-weight: bold;
}

.gray {
  color: var(--gray);
}

.truncate {
  text-overflow: ellipsis;
}

.green100 {
  color: var(--green-100);
}

.yellow100 {
  color: var(--yellow-100);
}

.red100 {
  color: var(--red-100);
}
