.main {
  height: 100svh;
  width: 100svw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.h1 {
  font-size: var(--h1);
  margin: 0;
  text-align: center;

  color: var(--black);
  border-bottom: 2px solid var(--black);
}

.bottom-container {
  margin-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
}

.scroll-down {
  color: var(--black);
  font-size: var(--b2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--s4);
}

.new-world-button {
  display: block;
  font-size: var(--b2);
  margin: var(--s4);
  padding: var(--s4);
  background: var(--black);
  color: var(--white);
  font-weight: bold;
  text-align: center;
  text-decoration: none;

  outline: none;
  border: none;
}

.about {
  height: 100svh;
  width: 100svw;
  color: var(--black);
}

.h2 {
  margin: var(--s4);
  font-size: var(--h2);
  text-align: center;
}

.p {
  margin: var(--s4);
  font-size: var(--b2);
}
