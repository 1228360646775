.resource-option-group {
  display: flex;
  gap: var(--s4);
}

.resource-option {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--s3);

  border-radius: 2px;
  border: 1px solid var(--black);
  padding: var(--s4);

  opacity: 0.5;
  &--selected {
    opacity: 1;
  }
}
