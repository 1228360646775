.tab-bar {
  font-size: var(--b2);
  padding-bottom: env(safe-area-inset-bottom);

  background: color-mix(
    in srgb,
    var(--white) 50%,
    transparent
  );
  backdrop-filter: blur(6px);
  position: sticky;
  bottom: 0;
  width: 100dvw;
  display: flex;
  flex-direction: row-reverse;
  overflow-x: auto;

  height: var(--height);
  box-sizing: border-box;
  border-top: 1px solid var(--gray);
}

.item {
  padding: var(--s4);
  color: var(--black);
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--s3);

  &--active {
    font-weight: bold;
  }
}
