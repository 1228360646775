.h1 {
  font-size: var(--h1);
  margin: 0;
}
.h2 {
  font-size: var(--h2);
  margin: 0;
}
.h3 {
  font-size: var(--h3);
  text-transform: uppercase;
  margin: 0;
  padding: var(--s3) 0;
  border-bottom: 1px solid var(--gray);
}
